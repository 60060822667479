"use strict";
/**
 * @def tooltip
 * 
 * @CSSify styleName : TooltipHost
 * 
 */

var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');

var tooltipDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'input-tooltip',
//			attributes : [
//				{className : 'tooltip'}
//			],
			states : [
				{hidden : true}
			],
			props : [
				{contentToList : null}
			],
			reactOnParent : [
				{
					from : 'valid',
					to : 'hidden',
				}
			],
			targetSlotIndex : 1,
			templateNodeName : 'p'/**@CSSifyStyle componentStyle : TooltipHost */
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'header'
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'section'
			}, null, 'hostOnly')
		]
	}, 'tooltip_elem', 'rootOnly');

	return moduleDef;
}

tooltipDef.__factory_name = 'tooltipDef';
module.exports = tooltipDef;