"use strict";
/**
 * @def visiblyTypedKeyValuePair
 * @CSSify styleName : VisiblyTypedKeyValuePairHost
 * @CSSifyRule rule : host 
 * @CSSifyRule rule : div_2ndChild pointer
 * 
 */

var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');

var visiblyTypedKeyValuePairDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "block",
		"marginLeft": "48px",
		"width": "-moz-fit-content",
		"padding": "0px 12px 0px 7px",
		"white-space": "nowrap",
		"boxSizing": "border-box"
	},
	{
		"selector": "div",
		"display": "inline-block"
	},
	{
		"selector": "div:nth-child(4)",
		"fontSize": "12px"
	},
	{
		"selector": ":host([displayedas='ISO fullBox']) div:nth-child(4):before, :host([displayedas='ISO Box']) div:nth-child(4):before, :host([displayedas='ISO streamDesc']) div:nth-child(4):before",
		"content": "'['"
	},
	{
		"selector": ":host([displayedas='ISO fullBox']) div:nth-child(4):after, :host([displayedas='ISO Box']) div:nth-child(4):after, :host([displayedas='ISO streamDesc']) div:nth-child(4):after",
		"content": "']'"
	},
	{
		"selector": ":host(:hover) div:nth-child(4):before, :host(:hover) div:nth-child(4):after"
	},
	{
		"selector": ":host([selected]), :host([selected]) div:nth-child(4)",
		"fontSize": "14px",
		"backgroundColor": "#EFEFEF"
	},
	{
		"selector": ":host([selected]), :host([selected][displayedas='ISO fullBox'])",
		"margin": "-1px 0 -1px 40px",
		"border": "1px dashed #999"
	},
	{
		"selector": ":host([selected]) div:nth-child(4):before, :host([selected]) div:nth-child(4):after",
		"fontWeight": "bold"
	},
	{
		"selector": ":host([displayedas=string]) div:nth-child(4)"
	},
	{
		"selector": ":host([displayedas=number]) div:nth-child(4)"
	},
	{
		"selector": ":host([displayedas=array]) div:nth-child(4)"
	},
	{
		"selector": ":host([displayedas=object]) div:nth-child(4)"
	},
	{
		"display": "inline-block",
		"minWidth": "18px",
		"maxWidth": "18px",
		"maxHeight": "24px",
		"fontFamily": "Glyphicons Halflings",
		"marginRight": "-3px",
		"padding": "3px",
		"cursor": "pointer",
		"selector": ".glyphicon"
	},
	{
		"selector": "div.icofont-file-stack",
		"fontFamily": "IcoFont",
		"fontSize": "14.5px",
		"maxWidth": "24px",
		"maxHeight": "24px",
		"padding": "4px 5px 0px 5px",
		"transform": "translateY(-5px)"
	},
	{
		"selector": ":host([displayedas=string]) .icofont-file-stack, :host([displayedas=number]) .icofont-file-stack",
		"visibility": "hidden"
	},
	{
		"selector": "div.icofont-file-stack:before",
		"content": "'\\e96e'"
	},
	{
		"selector": ":host([selected]) div.icofont-file-stack:before"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'VisiblyTypedKeyValuePairHostStyles'
	}
	
	return new TypeManager.createComponentDef({
		UID : 'dummy_for_style',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	}, 'visiblyTypedKeyValuePair_style');
}

module.exports = visiblyTypedKeyValuePairDef;