"use strict";
/**
 * @def treeBranch
 * @isGroup true
 * 
 * @CSSify styleName : AbstractTreeBranch/true
 * @CSSifyRule rule : host block
 * @CSSifyRule rule : header hPadding
 * 
 */


var TypeManager = require('src/core/TypeManager');

var CreateStyle = require('src/UI/generics/GenericStyleConstructor');
//var pseudoSlotsStyles = require('src/UI/defs/extraStyles/pseudoSlot');


var treeBranchDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var branchStyles = [

	{
		"selector": "header, span",
		"display": "inline-block"
	},
	{
		"selector": "[hidden]",
		"visibility": "hidden"
	},
	{
		"selector": ":host",
		"display": "block",
		"marginLeft": "34px",
		"overflow": "hidden",
		"boxSizing": "border-box"
	},
	{
		"selector": ":host-context(folded-tree)",
		"marginLeft": "0"
	},
	{
		"selector": ":host(:not([expanded]))",
		"height": "24px"
	},
	{
		"selector": "header",
		"padding": "0 7px"
	},
	{
		"selector": "span.json-type, span.bracket",
		"fontSize": "12px",
		"padding": "0px 1px",
		"cursor": "pointer"
	},
	{
		"selector": "header:hover span.bracket"
	},
	{
		"selector": "header[selected] span.bracket"
	},
	{
		"selector": "header[selected], header[selected] span.json-type",
		"backgroundColor": "#EFEFEF"
	},
	{
		"selector": "header[selected], header[selected][role]",
		"margin": "0px 0px -2px 0px",
		"border": "1px dashed #999"
	},
	{
		"selector": "header[selected] .icofont-box:before",
		"color": "#777"
	},
	{
		"display": "inline-block",
		"minWidth": "18px",
		"maxWidth": "18px",
		"maxHeight": "24px",
		"fontFamily": "Glyphicons Halflings",
		"fontSize": "12px",
		"marginRight": "-5px",
		"padding": "3px",
		"cursor": "pointer",
		"selector": ".glyphicon"
	},
	{
		"selector": ".glyphicon:nth-child(2)",
		"marginLeft": "-18px"
	},
	{
		"selector": ".glyphicon-arrow-right:before",
		"content": "'\\e080'"
	},
	{
		"selector": ".glyphicon-arrow-down:before",
		"content": "'\\e114'",
		"color": "#ffa500"
	},
	{
		"selector": "span.icofont-box, span.icofont-listing-number",
		"fontFamily": "IcoFont",
		"fontSize": "12px",
		"maxWidth": "24px",
		"padding": "0px 4px 0 0px",
		"transform": "translateY(-4px)"
	},
	{
		"selector": "span.icofont-box[hidden], span.icofont-listing-number[hidden]",
		"display": "none"
	},
	{
		"selector": ".icofont-box:before",
		"content": "'\\eec1'"
	},
	{
		"selector": ".icofont-listing-number:before",
		"content": "'\\ef76'"
	},
	{
		"selector": "span.icofont-box:hover:before",
		"content": "'\\eec2'"
	},
	{
		"selector": "span.icofont-listing-number:hover:before",
		"content": "'\\eec2'"
	},
	{
		"selector": ":host(:not([expanded])) span.icofont-box:before",
		"content": "'\\eec2'"
	},
	{
		"selector": ":host(:not([expanded])) span.icofont-listing-number:before",
		"content": "'\\eec2'"
	}

	];
	var branchStylesUseCache = {
		use : true,
		nameInCache : 'AbstractTreeBranchStyles'
	}
	
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'CompoundComponent',
			nodeName : 'tree-branch',
			states : [
				{expanded : undefined}
			],
			props : [
				{headerTitle : undefined},
				{displayedas : undefined},
				{selected : undefined},
				{branchintree : undefined},
				{nodeintree : undefined}
			],
			reactOnParent : [
				{
					from : 'selected',
					to : 'selected'
				}
			],
				sWrapper : CreateStyle(
						branchStylesUseCache.use ? branchStylesUseCache.nameInCache : null,
						branchStyles
					)
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'VaritextButtonWithPictoFirst',
				nodeName : 'header',
				// this is a big hack of shit (should be an attribute, but not... should be a "DOM" attribute... -> setAttribute(). TODO: fix after re-implementation of _arias&glyphs)
				states : [
					{role : "heading"},
					{expanded : undefined},
					{displayedas : undefined},
					{selected : undefined},
					{branchintree : undefined},
					{nodeintree : undefined}
				],
				reactOnParent : [
					{
						from : 'headerTitle',
						to : 'content'
					},
					{
						from : 'selected',
						cbOnly : true,
						subscribe : function(value) {this.streams.selected.value = value === this._UID ? 'selected' : null;}
					},
					{
						from : 'expanded',
						to : 'expanded'
					},
					{
						from : 'displayedas',
						to : 'displayedas'
					},
					{
						from : 'branchintree',
						to : 'branchintree'
					},
					{
						from : 'nodeintree',
						to : 'nodeintree'
					}
				]
			})
		]
	}, null, 'rootOnly');
	
	return moduleDef;
}

treeBranchDef.__factory_name = 'treeBranchDef';
module.exports = treeBranchDef;