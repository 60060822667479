"use strict";
/**
 * @def FileSelector
 * @isGroup true
 * 
 * @CSSify styleName : FileSelectorHost
 * @CSSify styleName : FileSelectorTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var DF = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var FileSelectorDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "block",
		"boxSizing": "border-box",
		"background": "#1a2327",
		"height": "100%",
		"paddingLeft": "15px"
	},
	{
		"selector": ":host p",
		"margin": "0",
		"cursor": "default"
	},
	{
		"selector": ":host p[selected]",
		"background": "#2e3138"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'FileSelectorHostStyles'
	}
	
	
	
	var moduleDef = DF.createDef({
		host : DF.createDef({
			isCompound : true,
			nodeName : 'FileSelector'.toLowerCase() + '-component',
			attributes : [
				{'aria-role' : 'select'}
			],
			props : [
//				{updateChannel : undefined},
//				{populate : undefined}
			],
			reactOnParent : [
				{
					cbOnly : true,
					from : 'serviceChannel',
					subscribe : function(response) {
//						console.log(response);
//						console.log(this.typedSlots);
						
						// Proceed
						var fileList, endPointName, endPointIndex;
						if (response.endPointName) {
							endPointName = response.endPointName;
							fileList = response.payload.slice(0); 
						}
						
						endPointIndex = (this.slotsAssociation && this.slotsAssociation[endPointName]) || 0;
						this.typedSlots[endPointIndex].resetLength();
						
						fileList.forEach(function(filename, key) {
//							console.log(this.typedSlots[endPointIndex].newItem(filename));
							this.typedSlots[endPointIndex].push(
								this.typedSlots[endPointIndex].newItem(filename)
							);

							// NOT NEEDED : (reminder) the ReactiveDataset shall automagically assign to the instantiated child-component
							//							(via the corresponding stream)
							// 							the value held by the 'fileName' prop of each of its entries
							//							In the slotDef of he FileSelector component, the "fileName"" stream
							//							is reflected on the "content" stream
							//							(via reactOnSelf) of the SimpleText component
//							this._children[key].streams.content.value = this.typedSlots[endPointIndex][key].fileName;
						}, this);
					}
				}
			],
			subscribeOnChild : [
				{
					on : 'clicked_ok',
					subscribe : function(e) {
						
						this._children.forEach(function(component) {
							// avoid the "input" component
							if (component.objectType === 'VisibleStateComponent'
								&& component.streams.selected.value === true)
								component.streams.selected.value = null;
						});
						e.data.self.streams.selected.value = true;
						
						this.hiddenInput.setValue(e.data.self.streams.content.value);
					}
				}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		members : [
			DF.createDef({
				host : DF.createDef({
					type : 'TextInput',
					attributes : [
						{title : '-filename'}
					],
					// TODO: The following should become a style override (as for now, there's no style in DB for TextInput components)
					sWrapper : CreateStyle([
						{
							selector : ':host label, :host input, :host smart-tooltip',
							display : 'none'
						}
					])
				})
			})
		]
	});
//	console.error(moduleDef.getGroupHostDef());
	return moduleDef;
}

module.exports = FileSelectorDef;