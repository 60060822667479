"use strict";
/**
 * @def ColorScaleSamplerComponent
 * @isGroup true
 * 
 * @CSSify styleName : ColorScaleSamplerComponentHost/true
 * @CSSify styleName : ColorScaleSamplerComponentTemplate
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var ColorScaleSamplerComponentDef = function(uniqueID, options, model) {
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	var moduleDef = TypeManager.createComponentDef({
		UID : 'dummy'/**@CSSifyStyle componentStyle : ColorScaleSamplerComponentHost */
	}, 'ColorScaleSamplerComponentDefaultHostDef');
	
	return moduleDef;
}

module.exports = ColorScaleSamplerComponentDef;