"use strict";
/**
 * @def todoMVC_row
 * @isGroup true
 * 
 */

var TypeManager = require('src/core/TypeManager');
var keyCodes = require('src/events/JSkeyboardMap');

var todoMVC_rowDef = function() {

	/*
	 * Build a def that is the template of a "row" (it's our "todo" class)
	 */
	var rowDef = TypeManager.createDef({
			host : TypeManager.createDef({
					type : 'CompoundComponent',
					nodeName : 'li',
					reactOnParent : [
						{
							from : 'none',
							to : 'active',
							filter : function(value) {return (value !== false && value !== true);},		// the row doesn't react on "none" with "active" unless the "none" app state is forced to "active" or null
							map : function(value) {return !value ? true : null;}						// this allows having a "none" state that reflects the -visible- state of the app, and the buttons may consume it to hide or show themselves
						}																				// thus we avoid the otherwise dirty side-effects of row-state mutation when the displayed list is filtered
					],
					reactOnSelf : [
						{
							from : 'active',
							to : 'className',
							map : function(value) {return value !== true ? 'completed' : null;}		// "className" reacts on "active" which is the "source of truth"
						},
						{
							from : 'editing',
							to : 'className'
						}
					],
					states : [
						{className : undefined},
						{hidden : undefined}
					],
					props : [
						{active : undefined},
						{editing : undefined},
						{todoTitle  : undefined}
					],
					subscribeOnChild : [
//						{
//							on : 'update',
//							subscribe : function(e) {
////								if (typeof e.data.editing !== 'undefined' && this.streams['active'].value) {
////									
////								}
////								else 
//								if (e.data.action === 'stop_edit') {
//									this.streams['editing'].value = null;
//								}
//								else if (e.data.action === 'todoTitle') {
//									this.streams['editing'].value = null;
//									this.streams['todoTitle'].value = e.data.value; // the todoTitle prop is instantly reflected on the model through the stream, and the backend update shall happen through parallel event-bubbling (here acts an implicit callback)
//								}
//							}
//							
//						},
						{
							on : 'stroke_enter',
							subscribe : function(e) {
								this.streams['editing'].value = null;
								this.streams['todoTitle'].value = e.data.value; // the todoTitle prop is instantly reflected on the model through the stream, and the backend update shall happen through parallel event-bubbling (here acts an implicit callback)
								this.trigger('update', {action : 'todoTitle', value : e.data.value}, true);
							}
						},
						{
							on : 'stroke_escape',
							subscribe : function(e) {
								this.streams['editing'].value = null;
							}
						},
						{
							on : 'checked',
							subscribe : function(e) {
								console.log('checked');
								this.streams['active'].value = e.data.checked ? null : true;
								this.trigger('update', {completed : e.data.checked ? this._key : null}, true);
							}
						},
						{
							on : 'dblClicked_ok',
							subscribe : function(e) {
								if (e.data.self.streams.editing) {
									console.log(e.data.self.streams.editing.value)
									this._children[0].view.getMasterNode().focus();
									this.streams['editing'].value = e.data.self.streams.editing.value;
									console.log(this.streams['editing'], this.streams['className'].value)
								}
							}
						},
						{
							on : 'clicked_ok',
							subscribe : function(e) {
								if (e.data.self.streams.remove)
									this.trigger('update', {action : 'todoTitle', value : '', idx : this._key}, true); // update event shall bubble to the root component : by spec., an empty string means "destroy"
							}
						}

					]
				}),
			subSections : [
				TypeManager.createComponentDef({
					nodeName : 'div',
					attributes : [
						{className : 'view'}
					]
				}),
				TypeManager.createComponentDef({
					nodeName : 'div',
					attributes : [
						{className : ''}
					]
				})
			],
			members : [
				TypeManager.createComponentDef({
					type : 'OnEnterValidableTextInput',
					section : 1,
					attributes : [
						{className  : 'edit'}
//						{type  : 'text'},
//						{onblur : function(e) {
//							e.target._component.trigger('update', {action : 'todoTitle', value : e.target.value.trim(), idx : e.target._component._parent._key}, true);
//							}
//						},
//						{onkeyup : function(e) {
//								if (e.keyCode === keyCodes.indexOf('ENTER')) {
//									// Hack needed here (more a "non-straightforward" implementation than a "hack", though): _component._parent._children[0] 
//									// (registering the subSections as child had too much side-effects: 
//									// 		=> so no one has currently automatically subscribed to the events this component fires...
//									// 			we could also have solved that by adding a "subscribeOnSelf" query on the parent's definition: a slightly cleaner solution, but technically identical)
//									e.target._component._parent._children[0].trigger('update', {action : 'todoTitle', value : e.target.value.trim()}, true);
//								}
//								else if (e.keyCode === keyCodes.indexOf('ESCAPE')) {
//									// And here...
//									e.target._component._parent._children[0].trigger('update', {action : 'stop_edit'}, true);
//								}
//							}
//						}
					],
					reactOnParent : [
						{
							to : 'hidden',
							from : 'editing',
							map : function(value) {return value === 'editing' ? null : 'hidden';}
						},
						{
							cbOnly : true,
							from : 'editing',
							subscribe : function(value) {if (value === 'editing') this.view.getMasterNode().focus();}
						},
						{	
							cbOnly : true,
							from : 'todoTitle',
							subscribe : function(value) {
								this.view.getMasterNode().value = value;
							}
						}
					],
					props : [
						{hidden : 'hidden'}
					]
				}),
			
				TypeManager.createComponentDef({
					type : 'CheckboxInput',
					nodeName : 'input',
					section : 0,
					attributes : [
						{className  : 'toggle'}
					],
					reactOnParent : [
						{
							from : 'active',
							to : 'checked',
							map : function(value) {return !value ? 'checked' : null;}
						}
					]
				}),
				TypeManager.createComponentDef({
					type : 'VisibleStateComponent',
					nodeName : 'label',
					section : 0,
					attributes : [
						{className  : "view"},
						// Those "native" DOM event subscriptions suck.
						// But the purpose of these "sample apps" like the todoMVC is to show how to implement a spec with "the tools that are parts of the framework".
						// In a "real world" implementation, one would have abstracted this behavior in a component.
						// A convenient "way to go" would be to mimic the VaritextButton, which is already a "specialized" implementation of the SimpleText,
						// 		overriding the "registerEvents" hook to handle double-click instead of simple-click,
						// 		and why not declaring a "component-level" event, named "status", or something similar...
						// 		and thus have an explicit event triggered on a "status change" from "saved" to "editing", for example... 
//						{ondblclick : function(e) {
//								e.target._component.trigger('update', {editing : 'editing'});
//							}
//						}
					],
					props : [
						{editing : 'editing'}
					],
					reactOnParent : [
						{
							cbOnly : true,
							from : 'todoTitle',
//							to : 'content',
							subscribe : function(value) {this.view.setTextContent(value);}
						}
					]
				}),
				TypeManager.createComponentDef({
					type : 'VisibleStateComponent',
					nodeName : 'button',
					section : 0,
					attributes : [
						{className  : 'destroy'}
						// Here too... Attaching that way a handler to a native DOM event is clearly a baaaaad smell...
						// Moreover, the VisibleStateComponent already has a "onclick" handler. It triggers the "clicked" event,
						// 		but that would have been semantically unclear when handling it inside of the component representing the "row":
						// 	So triggering "update", which is an abstract (framework-level) event does the job,
						// 		without having to extend the VisibleStateComponent.
						// -> "Do it with the standards", thats's how we went through the making of this implementation.
//						{onclick : function(e) {
//							console.log('clicked');
//							e.target._component.trigger('update', {remove : true});
//							}
//						}
					],
					props : [
						{remove : true}
					]
				})
			]
		});
	
//	console.log(rowDef)
	return rowDef;
}

module.exports = todoMVC_rowDef;