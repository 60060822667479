"use strict";
/**
 * @def BigModalBox
 * @isGroup true
 * 
 * @CSSify styleName : BigModalBoxHost
 * @CSSify styleName : BigModalBoxTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var BigModalBoxDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexDirection": "row",
		"position": "absolute",
		"left": "50%",
		"top": "50%",
		"boxSizing": "border-box",
		"background": "#212A2F",
		"width": "562px",
		"height": "270px",
		"border": "1px #383838; solid",
		"borderRadius": "5px",
		"marginLeft": "-270px",
		"marginTop": "-135px",
		"padding": "7px",
		"zIndex": "22",
		"visibility": "visible",
		"boxShadow": "2px 2px 10px 0px rgba(0,0,0,.12)"
	},
	{
		"selector": ":host section:nth-child(2)"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'BigModalBoxHostStyles'
	}
	
	

	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent', 				// this is not implicit if we want to use inference when building the def through the factory
			nodeName : 'Big-ModalBox'.toLowerCase(),
			states : [
				{hidden : null}
			],
			subscribeOnChild : [
				{
					on : 'cancel',
					subscribe : function(e) {
						// All of these 3 solutions are valid solutions
//						this.view.styleHook.s.updateRule({visibility : 'hidden'}, ':host');
//						this.view.hide();
						this.streams.hidden.value = true;
					}
				},
				{
					on : 'success',
					subscribe : function(e) {
						this.streams.hidden.value = true;
					}
				}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		subSections : [
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'ComponentWithView',
//					nodeName : 'section'
//				})
//			}),
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'ComponentWithView',
//					nodeName : 'section'
//				})
//			})
		],
		members : [
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'SubmitButton',
//					nodeName : 'button',
//					section : 1,
//					props : [
//						{text : 'Open File'}
//					]
//				})
//			}),
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'CancelButton',
//					nodeName : 'button',
//					section : 1,
//					props : [
//						{text : 'Cancel'}
//					]
//				})
//			})
		]
	});
//	console.log(moduleDef);
	return moduleDef;
}

module.exports = BigModalBoxDef;