"use strict";
/**
 * @def ObjectPairsListBuilderComponent
 * @isGroup true
 * 
 * @CSSify styleName : ObjectPairsListBuilderComponentHost/true
 * @CSSify styleName : ObjectPairsListBuilderComponentTemplate/true
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var ObjectPairsListBuilderComponentDef = function(uniqueID, options, model) {
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0",
		"boxSizing": "border-box"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'ObjectPairsListBuilderComponentHostStyles'
	}
	
	
	var slotDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'ComponentWithView',
			nodeName : 'comp-nursery',
			states : [],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			]/**@CSSify Style componentStyle : ObjectPairsListBuilderComponentTemplate */
		}, null, 'hostOnly')
	}, null, 'rootOnly');
	
	
	/*
	 * Build the schematic-def of the component: 
	 * 
	 * this one is pretty special...
	 * 
	 * This def is the base-def for any ObjectPairsListBuilderComponent instance
	 * But, CAUTION: In order to implement different -individual- defs for the slots (and/or being able to -reduce- the slots count, if needed, without breaking the execution)
	 * 		=> we have to take into account that there is a !second! def obj, which is injected directly into the ObjectPairsListBuilderComponent ctor
	 * 		=> so the ObjectPairsListBuilderComponent must then be extended through prototypal inheritance, and :
	 * 			-*- eventually, his slotsDef property overridden (pre-defined) in the derived ctor
	 * 			-*- eventually, his slotsCount property {number} also overridden (pre-defined) in the derived ctor
	 * 			-*- and if the type of the slots must be different than "Dataset", his affectSlots() method must be overridden
	 */
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'object-pairs',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		lists : [
			TypeManager.createComponentDef({
				type : 'ComponentList',
				template : slotDef
			})
		]
	}, null, 'rootOnly');
	
	return moduleDef;
}

module.exports = ObjectPairsListBuilderComponentDef;