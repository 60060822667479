"use strict";
/**
 * @def RootViewComponent
 * @isGroup true
 * 
 * @CSSify styleName : RootViewComponentHost/false
 * @CSSify styleName : RootViewComponentHeader/false
 * @CSSify styleName : RootViewComponentPage/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var RootViewComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must "stick" to the annotation (ie. be RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var pageStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"width": "100%",
		"flex": "1 1 0",
		"flexFlow": "row",
		"boxSizing": "border-box",
		"background": "0",
		"border": "0",
		"boxShadow": "0",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	}

	];
	var pageStylesUseCache = {
		use : false,
		nameInCache : 'RootViewComponentPageStyles'
	}

	var headerStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"width": "100%",
		"flex": "1 1 0",
		"flexFlow": "row",
		"boxSizing": "border-box",
		"background": "0",
		"border": "0",
		"boxShadow": "0",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	}

	];
	var headerStylesUseCache = {
		use : false,
		nameInCache : 'RootViewComponentHeaderStyles'
	}

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"width": "100%",
		"height": "100%",
		"flex": "1 1 100%",
		"flexFlow": "column",
		"position": "absolute",
		"overflow": "hidden",
		"boxSizing": "border-box",
		"background": "transparent",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": "iframe",
		"height": "75%",
		"border": "1px solid #444",
		"backgroundColor": "#333",
		"boxSizing": "border-box",
		"margin": "0"
	}

	];
	var hostStylesUseCache = {
		use : false,
		nameInCache : 'RootViewComponentHostStyles'
	}
	

	 

	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
//			type : 'ComposedCompnent', 				// this is implicit, as we call the CompoundComponent ctor in the ctor
			nodeName : 'app-root',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'ComponentWithView',
				nodeName : 'app-header',
				sWrapper : CreateStyle(
						headerStylesUseCache.use ? headerStylesUseCache.nameInCache : null,
						headerStyles
					)
			}),
			TypeManager.createComponentDef({
				type : 'ComponentWithView',
				nodeName : 'app-body',
				sWrapper : CreateStyle(
						pageStylesUseCache.use ? pageStylesUseCache.nameInCache : null,
						pageStyles
					)
			})
		]
	}, null, 'rootOnly');
	
	var minimalModuleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
//			type : 'ComposedCompnent', 				// this is implicit, as we call the CompoundComponent ctor in the ctor
			nodeName : 'app-root',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		})
	}, null, 'rootOnly');
	
//	console.error(minimalModuleDef);
	
	return {
		moduleDef : moduleDef,
		minimalModuleDef : minimalModuleDef
	};
}

module.exports = RootViewComponentDef;