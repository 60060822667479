"use strict";
/**
 * @def FontSwatchComponent
 * @isGroup true
 * 
 * @CSSify styleName : FontSwatchComponentHost/false
 * @CSSify styleName : FontSwatchComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var InnerReactiveComponent = require('src/UI/generics/InnerReactiveComponent');
var StylesheetWrapper = require('src/editing/StylesheetWrapper');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');

var FontSwatchComponentDef = function(uniqueID, options, model) {

	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "inline-block",
		"_width": "77px",
		"padding": "0",
		"border": "0",
		"borderWidth": "1px",
		"borderRadius": "3px",
		"boxSizing": "border-box"
	},
	{
		"selector": "input[type=text]",
		"display": "inline-block",
		"fontSize": "inherit",
		"color": "rgba(255,255,255,1)",
		"border": "1px #99999900 solid",
		"background": "transparent",
		"maxWidth": "107px",
		"height": "19x",
		"padding": "3px",
		"borderRadius": "3px 0 0 3px",
		"verticalAlign": "baseline",
		"boxSizing": "border-box",
		"WebkitAppearance": "none",
		"cursor": "pointer",
		"pointerEvents": "none"
	},
	{
		"selector": "label",
		"display": "none",
		"cursor": "pointer"
	},
	{
		"selector": "span",
		"display": "inline-block",
		"padding": "6px",
		"pointerEvents": "none"
	},
	{
		"selector": ":host(:nth-child(3))",
		"borderWidth": "1px 1px 1px 0",
		"borderRadius": "0 3px 3px 0"
	}

	];
	var hostStylesUseCache = {
		use : false,
		nameInCache : 'FontSwatchComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'font-swatch',
		targetSlotIndex : 0,
		templateNodeName : 'span',
		attributes : [
			{title : '-FontSwatch' + TypeManager.UIDGenerator.newUID()}
		],
		states : [
			{selected : undefined}
		],
		props : [
			{contentToList : undefined},
			{updateChannel : undefined}
		],
		reactOnSelf : [
			{
				from : 'updateChannel',
				cbOnly : true,
				subscribe : function(value) {
//					console.log(value);
//					console.log(this._key, this.propToAcquireFromDBType, value[this._key][this.propToAcquireFromDBType], value);
					value = this.adaptDBTypedValue(value);
//
//					var fontSize;
//					if (value.fontScale)
//						fontSize = Math.round(value.fontScale[this._key]);
//					else if (value.fontSize)
//						fontSize = Math.round(value.fontSize);
//					else
//						return;
//
//					this.view.styleHook.setTextInputFontSize(fontSize);
//					this.view.styleHook.setTextInputFontFamily(value.fontFamily);
					
					// contentToList automatically creates a span in the label node
//					this.streams.contentToList.value = [value.fontFamily];
					// setContentFromValueOnMemberView is the abstract version of the above "streamed" update mmethod
//					this.setContentFromValueOnMemberView(value.fontFamily, 1);
				}
			},
			{
				from : 'contentToList',
				cbOnly : true,
				subscribe : InnerReactiveComponent.prototype.updateTargetView
			}
		]
		,
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = FontSwatchComponentDef;