"use strict";
/**
 * @def MenuBar
 * @isGroup true
 * 
 * @CSSify styleName : MenuBarHost
 * @CSSify styleName : MenuBarTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var DF = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var MenuBarDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"width": "100%",
		"height": "44px",
		"flexFlow": "row",
		"boxSizing": "border-box",
		"background": "#2e3138",
		"border": "0",
		"padding": "0",
		"boxShadow": "2px 2px 10px 0px rgba(0,0,0,.12)"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'MenuBarHostStyles'
	}
	
	
	var moduleDef = DF.createDef({
//		host : DF.createDef({
			type : 'CompoundComponent',
//			isCompound : true,
			nodeName : 'menu-bar',
			states : [],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
//		})
	});
	
	
	
	return moduleDef;
}

module.exports = MenuBarDef;