"use strict";
/**
 * @dictionnary  
 */

module.exports = {
		ComponentWithView : require('src/core/Component').ComponentWithView,
		VisibleStateComponent : require('src/UI/generics/VisibleStateComponent'),
		SimpleText : require('src/UI/packages/minimal/SimpleText'),
		KeyboardSubmittableInput : require('src/UI/packages/minimal/KeyboardSubmittableInput')
}