"use strict";
/**
 * @constructor JScontentFromTextarea
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var appConstants = require('src/appLauncher/appLauncher');

var jsParserInterface = require('src/UI/_mixins/jsParserInterface');
var webStorageOnUpdate = require('src/UI/_mixins/webStorageOnUpdate');
//var scriptInjectorInterface = require('src/UI/_mixins/scriptInjectorInterface');

var createJScontentFromTextareaHostDef = require('src/UI/packages/minimal/JScontentFromTextarea/packageComponentDefs/JScontentFromTextareaHostDef');
//var createJScontentFromTextareaSlotsDef = require('src/UI/packages/minimal/JScontentFromTextarea/packageComponentDefs/JScontentFromTextareaSlotsDef');


// TODO: this type has a whole lot of caveats...
// Do a cleanup and watch out for any robustness improvement...
var JScontentFromTextarea = function(definition, parentView, parent) {
	Components.ComponentWithHooks.call(this, definition, parentView, parent);
	this.objectType = 'JScontentFromTextarea';
	this.isEditorReadyFlag = false;
}
JScontentFromTextarea.prototype = Object.create(Components.ComponentWithHooks.prototype);
JScontentFromTextarea.prototype.objectType = 'JScontentFromTextarea';

JScontentFromTextarea = Components.ExtensibleObject.prototype.addInterface(JScontentFromTextarea, jsParserInterface);
JScontentFromTextarea = Components.ExtensibleObject.prototype.addInterface(JScontentFromTextarea, webStorageOnUpdate);
//JScontentFromTextarea = Components.ExtensibleObject.prototype.addInterface(JScontentFromTextarea, scriptInjectorInterface);

//JScontentFromTextarea.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

JScontentFromTextarea.prototype.createDefaultDef = function() {
	return createJScontentFromTextareaHostDef();
}

JScontentFromTextarea.prototype.beforeRegisterEvents = function() {
	this.onDOMReadyPoll();
}

JScontentFromTextarea.prototype.onDOMReadyPoll = function(laterCallback) {
	var self = this;
	var laterCallback = laterCallback || self.reactOnViewPositionned.bind(self);
	
	var inter = setInterval(function() {
//		console.log(self.reactOnViewPositionned);
		if (self.view.getWrappingNode()) {
			clearInterval(inter);
			// ResizeObserver needs to be configured depending on the observed box : content-box (the default), and border-box.
			appConstants.resizeObserver.observe(self.view.getMasterNode(), laterCallback);
		}
	}, 512);
}

// Will instanciate a Codemirror instance (won't work before DOM "in-doc" presence... What a pitty...) 
JScontentFromTextarea.prototype.reactOnViewPositionned = function() {
//	var self = this;

//	console.log('reactOnViewPositionned');

	var editor = CodeMirror(
		this.view.getWrappingNode(), 
		{
//			value : 'You\'re getting friendlier, aren\'t you?\nNow, you pull your fingers out of your ass, moron.',
			lineNumbers: true,
    		gutter: true,
			indentWithTabs : true
		}
	);
	// move in firstChild position
	this.view.getWrappingNode().prepend(editor.display.wrapper);
//	console.log(editor);

	appConstants.resizeObserver.unobserve(this.view.getMasterNode());
	this.isEditorReadyFlag = true;
	// The current function is a callback on "resize" event:
	// 		=> Force "follow on" from here as we already passed the "hooks" step in AppIgnition.
	this.registerEditorEvents();
}

JScontentFromTextarea.prototype.registerEditorEvents = function() {
	var self = this;
	
	// Propagate the content of the editor
	this.view.getWrappingNode().querySelector('.CodeMirror').CodeMirror.on('change', function(e) {
		// TODO: evaluate precisely the need for throttling
		// 	(is for now realized at the "interface" level, as we use this in behaviors provided by mixins)
//		console.log('Editor content virtualy changed', e);
		self.trigger('update', {self_UID : self._UID, textContent : self.getEditorContent()})
	});
	
	// The initial state is defined here, as a default initialization step
	// For de-coupling purpose, the calls to localStorage can only come
	// form the appropriate interface of this component,
	// or from the global application controler
	this.setEditorContent('Initial Content');
}

JScontentFromTextarea.prototype.isEditorReady = function() {
	return this.isEditorReadyFlag;
}

JScontentFromTextarea.prototype.setEditorContent = function(value) {
	if (!this.isEditorReadyFlag) {
		this.onDOMReadyPoll(this.setEditorContent.bind(this, value));
		return;
	}
	
	this.view.getWrappingNode().querySelector('.CodeMirror').CodeMirror.setValue(value);
}

JScontentFromTextarea.prototype.getEditorContent = function(value) {
	if (!this.isEditorReadyFlag)
		return '';
	return this.view.getWrappingNode().querySelector('.CodeMirror').CodeMirror.getValue();
}





















module.exports = JScontentFromTextarea;