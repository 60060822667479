"use strict";
/**
 * @def BorderSwatchComponent
 * @isGroup true
 * 
 * @CSSify styleName : BorderSwatchComponentHost/true
 * @CSSify styleName : BorderSwatchComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var InnerReactiveComponent = require('src/UI/generics/InnerReactiveComponent');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');

var BorderSwatchComponentDef = function(uniqueID, options, model) {

	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host, select, label, div, span",
		"border": "0",
		"background": "transparent",
		"padding": "0",
		"borderRadius": "0",
		"verticalAlign": "baseline",
		"boxSizing": "border-box",
		"WebkitAppearance": "none"
	},
	{
		"selector": "select",
		"display": "inline-block",
		"width": "0px",
		"height": "0px",
		"cursor": "_pointer",
		"pointerEvents": "none"
	},
	{
		"selector": ":host",
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "space-evenly",
		"_width": "77px",
		"padding": "1px",
		"border": "1px #99999900 solid",
		"borderWidth": "1px 0 1px 1px",
		"borderRadius": "6px 0 0 6px",
		"boxSizing": "border-box"
	},
	{
		"selector": "span",
		"display": "inline-block",
		"padding": "0px 1px",
		"pointerEvents": "none"
	},
	{
		"selector": ":host(:nth-child(3))",
		"borderWidth": "1px 1px 1px 0",
		"borderRadius": "0 6px 6px 0"
	},
	{
		"selector": "label",
		"display": "none",
		"cursor": "pointer",
		"pointerEvents": "none"
	},
	{
		"selector": "div",
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "center",
		"background": "#FFF",
		"width": "16px",
		"height": "16px",
		"borderRadius": "3px"
	},
	{
		"selector": "div div",
		"background": "#FFF",
		"width": "10px",
		"height": "10px",
		"border": "1px dashed #000",
		"borderRadius": "0px"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'BorderSwatchComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'border-swatch',
		targetSlotIndex : 0,
		templateNodeName : 'span',
		attributes : [
			{title : '-BorderSwatch' + TypeManager.UIDGenerator.newUID()}
		],
		states : [
			{selected : undefined}
		],
		props : [
			{contentToList : undefined},
			{updateChannel : undefined}
		],
		reactOnSelf : [
			{
				from : 'updateChannel',
				cbOnly : true,
				subscribe : function(value) {
//					console.log(this._key, this.propToAcquireFromDBType, value[this._key][this.propToAcquireFromDBType], value);
//					value = this.adaptDBTypedValue(value);

//					var fontSize;
//					if (value.fontScale)
//						fontSize = Math.round(value.fontScale[this._key]);
//					else if (value.fontSize)
//						fontSize = Math.round(value.fontSize);
//					else
//						return;

//					this.view.styleHook.setTextInputFontSize(fontSize);
//					this.view.styleHook.setTextInputFontFamily(value.fontFamily);
					
					// contentToList automatically creates a span in the label node
//					this.streams.contentToList.value = [value];
					// setContentFromValueOnMemberView is the abstract version of the above "streamed" update mmethod
//					this.setContentFromValueOnMemberView(value, 1);
				}
			},
			{
				from : 'contentToList',
				cbOnly : true,
				subscribe : InnerReactiveComponent.prototype.updateTargetView
			}
		]
		,
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = BorderSwatchComponentDef;