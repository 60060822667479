"use strict";
/**
 * @def keyValueListSlots
 * @isGroup false
 * 
 * @CSSify hostName : keyValueListSlots
 * @CSSifyRule rule : host block
 * @CSSifyRule rule : header inlineBlock/pointer/border
 * @CSSifyRule rule : div inlineBlock/border
 * @CSSifyRule rule : div_2ndChild 
 * 
 */


var TypeManager = require('src/core/TypeManager');

var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var keyValueListSlotsDef = function(uniqueID, options, model) {
		
	// Some CSS stuff
	var styles = [
/*@CSSifySlot*/
		];
	
	var headerDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'VaritextButtonWithPictoFirst',
			nodeName : 'th',
			// this is a big hack of shit (should be an attribute, but not... should be a "DOM" attribute... -> setAttribute(). TODO: fix after re-implementation of _arias&glyphs)
			states : [
				{sortable : 'sortable'} 
			],
			props : [
				{headerTitle : undefined}
			],
			reactOnSelf : [
				{
					from : 'headerTitle',
					to : 'content'
				}
			],
//			sWrapper : CreateStyle('keyValueListSlotsStyles', null, styles).sWrapper,
		}, null, 'hostOnly')
	}, null, 'rootOnly');
	
	var sectionDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'ComponentWithView',
			nodeName : 'table'
		}, null, 'hostOnly')
	}, null, 'rootOnly');
	
	
	
	return {
		headerDef : headerDef,
		sectionDef : sectionDef
	};
}

keyValueListSlotsDef.__factory_name = 'keyValueListSlotsDef';
module.exports = keyValueListSlotsDef;