"use strict";
/**
 * @constructor SlidingPanel
*/
var appConstants = require('src/appLauncher/appLauncher');
var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var MouseWheelAnimation = require('src/core/MouseWheelAnimation');

var InnerReactiveComponent = require('src/UI/generics/InnerReactiveComponent');
var ScrollSlider = require('src/UI/packages/structs/ScrollSlider');

var createSlidingPanelDef = require('src/UI/packages_defs/panels/slidingPanelDef');
	
var SlidingPanel = function(def, parentView, parent) {
//	console.log(def);
	if (def.getHostDef().props.findObjectByKey('headerTitle') === false) {
		def.getHostDef().props.push(new TypeManager.propsModel({headerTitle : undefined}));
		def.getHostDef().streams.push(def.getHostDef().props.findObjectByKey('headerTitle'));
	}
	
	InnerReactiveComponent.call(this, def, parentView, parent);
	this.objectType = 'SlidingPanel';
	
	this.scrollSlider;
	this.targetContainer;
	this.targetBoundingBox = {w : 0, h : 0};
	this.viewBoundingBox = {w : 0, h : 0};
	this.softScroll;
}
SlidingPanel.prototype = Object.create(InnerReactiveComponent.prototype);SlidingPanel.prototype.objectType = 'SlidingPanel';

SlidingPanel.prototype._asyncInitTasks = [];
SlidingPanel.prototype._asyncInitTasks.push(new TypeManager.TaskDefinition({
	type : 'lateAddChild',
	task : function(definition) {
			var basicDef = TypeManager.mockDef();
//			console.log(this.view);
			new ScrollSlider(basicDef, this.view.subViewsHolder.subViews[1], this);
//			this._children[this._children.length - 1].view.parentView = this.view.subViewsHolder.subViews[1];
//			this.view.subViewsHolder.addMemberView(this._children[this._children.length - 1].view);
			this.scrollSlider = this._children[this._children.length - 1];
			
			this.scrollSlider.addEventListener('slide', function(e) {
				this.targetContainer.style.marginTop =  - (e.data.value).toString() + 'px';
			}.bind(this));
		}
	})
);
	
SlidingPanel.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'sliding-panel',
			templateNodeName : 'li',
			targetSlotIndex : 2,
			states : [
				{scrollbarghosted : undefined},
				{panelghosted : undefined},
				{headerghosted : undefined}
			],
			props : [
				{updateTrigger : undefined}//,
//				{headerTitle : undefined}							// hint: we have to choose between a text by default, or a text always defined by the implementation
			],
			reactOnSelf : [
				{
					cbOnly : true,
					from : 'updateTrigger',
					subscribe : function () {
						this.resetSlider();
						this.initSlider();
						this.scrollSlider.onDOMReadyInit();
//						this.render();
					}
				},
				{
					cbOnly : true,
					from : 'headerTitle',
					subscribe : function(val) {
						
						this.setContentFromArrayOnEachMemberView(val);
						this.streams.headerghosted.value = Array.isArray(val) && val.length ? 'unghosted' : 'ghosted';
					}
				},
				{
					cbOnly : true,
					from : 'headerghosted',
					subscribe : function(val) {
						if (val === 'unghosted')
							this.view.subViewsHolder.memberViews[0].currentViewAPI.getMasterNode().style.display = 'block';
						else
							this.view.subViewsHolder.memberViews[0].currentViewAPI.getMasterNode().style.display = 'none';
						
						this.resetSlider();
						this.initSlider();
						this.scrollSlider.onDOMReadyInit();
					}
				}
			],
			subscribeOnChild : [
				{
					on : 'resize',
					subscribe : function(e) {this.streams.updateTrigger.value = 'updated on child resize';}
				}
			],
			sWrapper : createSlidingPanelDef().getHostDef().sWrapper
		}, null, 'hostOnly'),
		subSections : [
			TypeManager.createComponentDef({
				nodeName : 'section',
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'aside',
			}, null, 'hostOnly')
		],
		members : [
			TypeManager.createComponentDef({
				nodeName : 'header',
				section : 0
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'div',
				section : 0,
				attributes : [
					{className : 'sliding_panel_shadow'}
				]
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'ul',
				section : 0
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'div',
				section : 0,
				attributes : [
					{className : 'sliding_panel_inverse_shadow'}
				]
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'footer',
				section : 0
			}, null, 'hostOnly')
		]
	}, 'SlidingPanelComponent', 'rootOnly');
}

SlidingPanel.prototype.getTargetBoundingBox = function() {
	var self = this;
	this.targetContainer = this.view.subViewsHolder.memberAt(2).getMasterNode().firstChild;

	var p = new Promise(function(resolve, reject) {
		var inter = setInterval(function() {
			if (self.targetContainer) {
				clearInterval(inter);
				(new Promise(function(resolve, reject) {
					// ResizeObserver needs to be configured depending on the observed box : content-box (the default), and border-box.
					appConstants.resizeObserver.observe(self.targetContainer, self.getTargetDimensions.bind(self, resolve));
					appConstants.resizeObserver.observe(self.view.getMasterNode(), self.getViewDimensions.bind(self, resolve));
				})).then(function() {
					resolve();
				});
			}
		}, 127);
	});
	return p;
}


SlidingPanel.prototype.getViewDimensions = function(resolve, e) {
	this.viewBoundingBox = {
		w : e.data.boundingBox.w,
		h : e.data.boundingBox.h
	};
//	console.log('getViewDimensions', e);
	appConstants.resizeObserver.unobserve(this.view.getMasterNode());
	if (resolve && this.targetBoundingBox.w)
		resolve();
}

SlidingPanel.prototype.getTargetDimensions = function(resolve, e) {
	this.targetBoundingBox = {
		w : e.data.boundingBox.w,
		h : e.data.boundingBox.h,
		scrollHeight : this.targetContainer.scrollHeight,
		scrollWidth : this.targetContainer.scrollWidth
	};
	appConstants.resizeObserver.unobserve(this.targetContainer);
	if (resolve && this.viewBoundingBox.w)
		resolve();
}

SlidingPanel.prototype.initSlider = function() {
	var self = this;
//	this.streams.panelghosted.value = 'unghosted';

	this.getTargetBoundingBox().then(function() {
//		console.log('targetBoundingBox', this.targetBoundingBox);
		if (this.targetBoundingBox.h < (this.viewBoundingBox.h - this.targetContainer.offsetTop))
			return;
		// Set container height
		this.targetContainer.parentNode.style.height = (this.targetContainer.parentNode.parentNode.clientHeight - this.targetContainer.offsetTop).toString() + 'px';
		// Set slider course
		this.scrollSlider.max = this.targetBoundingBox.h - (this.targetContainer.parentNode.parentNode.clientHeight - this.targetContainer.offsetTop);
		
//		this.scrollSlider.addEventListener('slide', function(e) {
//			this.targetContainer.style.marginTop =  - (e.data.value).toString() + 'px';
//		}.bind(this));

		// TODO: get effective lineHeight
		this.arbitraryLineHeight = 21;
		
		// set animation
		var animationCallback = function(offset) {

			this.scrollSlider._value = Math.min(this.scrollSlider._valueMax(), Math.max(0, this.scrollSlider._value + (-offset)));
	//		console.log(this.scrollSlider._value);
			this.scrollSlider.setPosition();
			
			if (this.scrollSlider._value > this.scrollSlider._valueMax() - this.arbitraryLineHeight)
				self.softScroll.updateTween(0, 'marginTop', -this.scrollSlider._valueMax());
			else if (this.scrollSlider._value < this.arbitraryLineHeight)
				self.softScroll.updateTween(0, 'marginTop', 0);
			else
				self.softScroll.updateTween(0, 'marginTop', -this.scrollSlider._value);
		};
		
		// Timelines for animating slider change on mousewheel
		this.softScroll = new MouseWheelAnimation(this.view.getMasterNode(), null, null, animationCallback.bind(this));
		this.softScroll.addTween(this.targetContainer, 'marginTop');
		
		this.streams.scrollbarghosted.value = 'unghosted';
	}.bind(this));
}

SlidingPanel.prototype.resetSlider = function() {
	if (!this.softScroll)
		return;
	this.softScroll.removeTween(0);
}

SlidingPanel.prototype.getHostedComponent = function() {
//	console.log(this._children);
	return this._children[1];
}


SlidingPanel.__factory_name = 'SlidingPanel';
module.exports = SlidingPanel;
