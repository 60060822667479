"use strict";
/**
 * @def IFrameTitleComponent
 * @isGroup true
 * 
 * @CSSify styleName : IFrameTitleComponentHost
 * @CSSify styleName : IFrameTitleComponentTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var IFrameTitleComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"boxSizing": "border-box",
		"background": "#2A3240",
		"borderBottom": "1px #FFFFFF solid",
		"display": "inline-block",
		"padding": "9px 22px 9px 15px"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'IFrameTitleComponentHostStyles'
	}
	
	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			nodeName : 'IFrame_title'.toLowerCase() + '-component',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		})
	});
	
	return moduleDef;
}

module.exports = IFrameTitleComponentDef;