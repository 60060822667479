"use strict";
/**
 * @def formComponent
 * @CSSify styleName : FormComponentHost
 * @CSSifyTheme themeName : basic-light
 */

var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');

var formComponentDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexDirection": "column",
		"boxSizing": "border-box",
		"padding": "7px"
	},
	{
		"selector": ":host section:nth-child(3)",
		"display": "flex",
		"flexDirection": "column",
		"alignItems": "end",
		"paddingTop": "5px"
	},
	{
		"selector": ":host section:nth-child(2)",
		"display": "flex",
		"flexDirection": "column",
		"width": "407px"
	},
	{
		"selector": ":host button",
		"background": "#0c849e",
		"color": "#EAEAEA",
		"font": "inherit",
		"fontSize": "14px",
		"padding": "12px 21px",
		"boxSizing": "border-box",
		"width": "122px",
		"marginBottom": "5px",
		"borderColor": "#383838 #303030 #303030 #383838",
		"cursor": "pointer"
	},
	{
		"selector": ":host fieldset",
		"width": "375px",
		"border": "1px solid #383838",
		"boxSizing": "border-box"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'FormComponentHostStyles'
	}
	
	return TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'smart-form',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
			// Reminder
//			states : [
//				{action : ''}
//			]
			
		}),
		subSections : [
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'ComponentWithView',
					nodeName : 'section'
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'ComponentWithView',
					nodeName : 'section'
				})
			})
		],
		members : [
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'SubmitButton',
//					nodeName : 'button',
//					section : 1,
//					props : [
//						{text : 'Open File'}
//					]
//				})
//			}),
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'CancelButton',
//					nodeName : 'button',
//					section : 1,
//					props : [
//						{text : 'Cancel'}
//					]
//				})
//			})
		]
	}, null, 'rootOnly'); 
}

module.exports = formComponentDef;