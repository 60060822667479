"use strict";
/**
 * @def FlexColumnComponent
 * @isGroup true
 * 
 * @CSSify styleName : FlexColumnComponentHost/true
 * @CSSify styleName : FlexColumnComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var FlexColumnComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0",
		"selector": ":host",
		"boxSizing": "border-box",
		"background": "0",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'FlexColumnComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'box-column',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = FlexColumnComponentDef;