"use strict";
/**
 * @def SelectInput
 * @isGroup true
 * 
 * @CSSify styleName : SelectInputHost
 * @CSSify styleName : SelectInputTemplate
 * @CSSify styleName : SelectInputHandle
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var SelectInputDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var handleStyles = [

	{
		"selector": ":host, span",
		"boxSizing": "border-box",
		"background": "none",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": "[hidden]",
		"visibility": "hidden"
	},
	{
		"selector": ":host",
		"color": "#000",
		"backgroundColor": "#AAA",
		"width": "27px",
		"height": "27px",
		"margin": "-1px -1px",
		"border": "1px outset #777",
		"borderRadius": "0px 3px 3px 0px"
	},
	{
		"_display": "inline-block",
		"fontFamily": "Glyphicons Halflings",
		"marginLeft": "6px",
		"cursor": "pointer",
		"selector": ".glyphicon"
	},
	{
		"selector": ".glyphicon:nth-child(2)",
		"_marginLeft": "6px"
	},
	{
		"selector": ".glyphicon-arrow-right:before",
		"content": "'\\e080'"
	},
	{
		"selector": ".glyphicon-arrow-down:before",
		"content": "'\\e114'",
		"color": "#ffDD22"
	}

	];
	var handleStylesUseCache = {
		use : undefined,
		nameInCache : 'SelectInputHandleStyles'
	}

	var templateStyles = [

	{
		"selector": ":host, option",
		"boxSizing": "border-box",
		"background": "none",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": ":host",
		"color": "#000",
		"display": "flex",
		"flex": "1 1 0",
		"flexFlow": "column",
		"backgroundColor": "#FFF",
		"maxHeight": "24px",
		"borderRadius": "0px 0px 2px 2px",
		"transition": "max-height 0.2s linear"
	},
	{
		"selector": ":host([expanded])",
		"boxShadow": "5px 5px #888 5px"
	},
	{
		"selector": "option",
		"display": "block",
		"minHeight": "26px",
		"padding": "3px 7px"
	},
	{
		"selector": "option:nth-child(2)",
		"border": "solid 1px",
		"borderColor": " #777 #777 #555555AA #555555AA",
		"marginTop": "-1px"
	},
	{
		"selector": ":host([expanded]) option:hover",
		"background": "#DDD"
	}

	];
	var templateStylesUseCache = {
		use : undefined,
		nameInCache : 'SelectInputTemplateStyles'
	}

	var hostStyles = [

	{
		"selector": ":host, div",
		"boxSizing": "border-box",
		"background": "none",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": ":host",
		"display": "flex",
		"justifyItems": "flex-start",
		"flex": "1 1 0",
		"border": "1px outset #888",
		"overflow": "hidden",
		"cursor": "pointer",
		"borderRadius": "2px"
	},
	{
		"selector": ":host([expanded])",
		"border": "1px solid #FFFFFF00"
	},
	{
		"selector": ":host([expanded]) pseudo-slot",
		"maxHeight": "none",
		"border": "1px inset #888"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'SelectInputHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
//			type : 'CompoundComponent',
			nodeName : 'smart-select',
			states : [
				{expanded : undefined}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
			
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'ComponentWithView',
				nodeName : 'pseudo-slot',
				states : [
					{expanded : undefined}
				],
				reactOnParent : [
					{
						from : 'expanded',
						to :  'expanded'
					}
				],
				sWrapper : CreateStyle(
						templateStylesUseCache.use ? templateStylesUseCache.nameInCache : null,
						templateStyles
					)
			}),
			TypeManager.createComponentDef({
				type : 'VaritextButtonWithPictoFirst',
				nodeName : 'select-handle',
				states : [
					{expanded : undefined}
				],
				reactOnParent : [
					{
						from : 'expanded',
						to :  'expanded'
					}
				],
				sWrapper : CreateStyle(
						handleStylesUseCache.use ? handleStylesUseCache.nameInCache : null,
						handleStyles
					)
			})
		]
	});
	
	return moduleDef;
}

module.exports = SelectInputDef;