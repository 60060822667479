"use strict";
/**
 * @def AppOverlayComponent
 * @isGroup true
 * 
 * @CSSify styleName : AppOverlayComponentHost/true
 * @CSSify styleName : AppOverlayComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var AppOverlayComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 100%",
		"selector": ":host",
		"position": "absolute",
		"width": "100%",
		"height": "25%",
		"top": "74%",
		"padding": "38px",
		"boxSizing": "border-box"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'AppOverlayComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'app-overlay',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = AppOverlayComponentDef;