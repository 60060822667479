"use strict";
/**
 * @def AbstractAccordion
 * @isGroup true
 * 
 * @CSSify styleName : AbstractAccordionHost/true
 * @CSSify styleName : AbstractAccordionPseudoSlot/true
 * @CSSifyTheme themeName : basic-light
 * 
 */


var TypeManager = require('src/core/TypeManager');

var CreateStyle = require('src/UI/generics/GenericStyleConstructor');
var pseudoSlotsStyles = require('src/UI/defs/extraStyles/pseudoSlot');


var AbstractAccordionDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host, *",
		"boxSizing": "border-box",
		"background": "0",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0",
		"margin": "0px 0px 9px 0"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'AbstractAccordionHostStyles'
	}
	
	var template = TypeManager.createComponentDef({
			type : 'ComponentWithView',
			nodeName : 'accordion-set',
			states : [
				{"accordion-set" : undefined}
			]/**@CSSify Style componentStyle : AbstractAccordionPseudoSlot */
	});
	

	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
//			type : 'ComposedCompnent', 				// this is implicit, as we call the CompoundComponent ctor in the TabPanel ctor
			nodeName : 'reactive-accordion',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		lists : [
			TypeManager.createComponentDef({
					type : 'ComponentList',
					template : template
			})
		]
	}, null, 'rootOnly');
	
	return moduleDef;
}

module.exports = AbstractAccordionDef;